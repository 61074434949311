import React from 'react';

const Popup = ({popupLink, closeHandler}) => {
	
	return (
		<div className="popup">
			<div className="popup--modal">
				<h3 className="popup--modal-title">
					Informacja
				</h3>
				<p
					className="popup--modal-description"
				>Uwaga! Za chwilę opuścisz stronę internetową Roche Polska i przejdziesz do zewnętrznej witryny
					internetowej.</p>
				<div>
					<button
						className="popup--modal-button"
						onClick={closeHandler}
					>
						ANULUJ
					</button>
					<a
						className="popup--modal-link"
						href={popupLink}
					>
						DALEJ
					</a>
				</div>
			</div>
		</div>
	)
}

export default Popup;