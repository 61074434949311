import React from 'react';

const Card = ({cardTitle, cardDescription, cardLink, openModalHandler}) => {
	return (
		<div
			className="cards--link"
			onClick={() => openModalHandler(cardLink)}
		>
			<h5 className="cards--link-title">{cardTitle}</h5>
			<p className="cards--link-description">{cardDescription}</p>
		</div>
	)
}

export default Card;