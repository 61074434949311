import React from 'react';

const SpanButton = ({spanLink, spanTitle, openModalHandler}) => {
	return (
		<span
			className='spanLink'
			onClick={()=>{openModalHandler(spanLink)}}
		>
			{spanTitle}
		</span>
	)
}

export default SpanButton;