import React from 'react';
import headerLogoImg from '../assets/img/headerLogo.png';
import SpanButton from './SpanButton';

const Header = ({openModalHandler}) => {
	
	return (
		<div className="header">
			<div className="header--logo">
				<img className="header--logo-img" src={headerLogoImg} alt="logo"/>
			</div>
			<div className="container">
				<h1 className="header--title">Szansa na<br/>dłuższe życie</h1>
				<h5 className="header--info">POLIVY - większe możliwości w leczeniu<br/>
					chorych na RR DLBCL od pierwszego nawrotu</h5>
				<p className="header--description">*Chorzy nie kwalifikujący się do przeszczepienia krwiotwórczych
					komórek
					macierzystych</p>
				<p className="header--description">1. Aktualna Charakterystyka Produktu leczniczego POLIVY znajduje się
					na
					stronie <SpanButton openModalHandler={openModalHandler} spanLink="https://www.roche.pl/content/dam/rochexx/roche-pl/roche_poland_rwd/pl_PL/documents/SmPC/Polivy%20-%20charakterystyka.pdf"
											   spanTitle="www.roche.pl"/>
				</p>
			</div>
		</div>
	)
}

export default Header;