import './App.css';
import React, {useState} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import VideoContainer from './components/VideoContainer';
import Cards from './components/Cards';
import Popup from './components/Popup';


const App = () => {
	const [isModal, setIsModal] = useState(false);
	const [popupLink, setPopupLink] = useState('')
	
	const closeModalHandler = () => {
		setIsModal(false);
		setPopupLink('');
	}
	
	const openModalHandler = (pLink) => {
		// console.log(pLink)
		setPopupLink(pLink)
		setIsModal(true)
	}
	
	return (
		<div className="appWrapper">
			<Header openModalHandler={openModalHandler}/>
			<main>
				<VideoContainer openModalHandler={openModalHandler}/>
				<Cards openModalHandler={openModalHandler}/>
			</main>
			<Footer openModalHandler={openModalHandler}/>
			{isModal && <Popup popupLink={popupLink} closeHandler={closeModalHandler}/>}
		</div>
	);
}

export default App;
