import React from 'react';
import footerLogo from '../assets/img/footerLogo.png';
import SpanButton from './SpanButton';

const Footer = ({openModalHandler}) => {
	return (
		<div className="footer container">
			<img className="footer--logo" src={footerLogo} alt="logo"/>
			<div className="footer--textarea">
				<p className="footer--textarea-text">
					&#9660;Produkt leczniczy jest dodatkowo monitorowany. Umożliwi to
					szybkie
					zidentyfikowanie nowych informacji o bezpieczeństwie. Osoby należące do fachowego personelu
					medycznego
					powinny zgłaszać wszelkie podejrzewane działania niepożądane.
					Zgłoszenia działań niepożądanych należy przekazywać do: Departamentu Monitorowania Niepożądanych
					Działań
					Produktów Leczniczych Urzędu Rejestracji Produktów Leczniczych, Wyrobów Medycznych i Produktów
					Biobójczych,
					Al. Jerozolimskie 181 C, 02-222 Warszawa, tel.: + 48 22 49 21 301; fax:+ 48 22 49 21 309; strona
					internetowa: https://smz.ezdrowie.gov.pl lub Roche Polska Sp. z o.o., ul. Domaniewska 39 B, 02-672
					Warszawa,
					tel. +48 22 345 18 88, fax +48 22 345 18 74 lub za pomocą formularza zgłoszeniowego znajdującego
					się pod
					adresem internetowym <SpanButton openModalHandler={openModalHandler}
													 spanLink="https://www.roche.pl/pl/bezpieczenstwo-produktow/zglaszanie-dzialan-niepozadanych.html"
													 spanTitle="www.roche.pl/portal/pl/zglaszanie_dzialan_niepozadanych"/>
				</p>
				<p className="footer--textarea-mNumber">M-PL-00002096</p>
			</div>
		</div>
	)
}

export default Footer;