import React from 'react';

const VideoContainer = ({openModalHandler}) => {
	return (
		<div className="container video">
			<div className="video--frameWrapper">
				<iframe
					title='videoFrame'
					src="https://player.vimeo.com/video/685370205?dnt=1"
					frameBorder="0"
					allow="autoplay; fullscreen; picture-in-picture"
				/>
			</div>
			<div
				className="video--button"
				onClick={()=>{openModalHandler('https://lyfelive.pl/')}}
			>
				<span>LYFE LIVE</span><br/>
				DOWIEDZ SIĘ<br/>WIĘCEJ
			</div>
		</div>
	)
}

export default VideoContainer