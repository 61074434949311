import React from 'react';
import Card from './Card';

const Cards = ({openModalHandler}) => {
	return (
		<div className="cards">
			<div className="container">
				<h3 className="cards--title">piśmiennictwo:</h3>
				<div className="cards--wrapper">
					<div className="cards--ls cards--left">
						<Card
							cardTitle="Sehn LH et al. Blood 2019"
							cardDescription="Polatuzumab Vedotin Plus Bendamustine with Rituximab in Relapsed/Refractory Diffuse Large B-Cell Lymphoma: Updated Results of a Phase Ib/II Randomized Study"
							cardLink="https://ashpublications.org/blood/article/134/Supplement_1/4081/424303/Polatuzumab-Vedotin-Plus-Bendamustine-with"
							openModalHandler={openModalHandler}
						/>
						<Card
							cardTitle="Sehn LH et al. Blood 2020"
							cardDescription="Polatuzumab Vedotin Plus Bendamustine and Rituximab in Relapsed/Refractory Diffuse Large B-Cell Lymphoma: Updated Results of a Phase Ib/II Randomized Study and Preliminary Results of a Single-Arm Extension"
							cardLink="https://ash.confex.com/ash/2020/webprogram/Paper137078.html"
							openModalHandler={openModalHandler}
						/>
					</div>
					<div className="cards--rs cards--right">
						<Card
							cardTitle="Sehn LH et al. J Clin Oncol 2020"
							cardDescription="Polatuzumab Vedotin in Relapsed or Refractory Diffuse Large B-Cell Lymphoma"
							cardLink="https://ascopubs.org/doi/full/10.1200/JCO.19.00172"
							openModalHandler={openModalHandler}
						/>
						<Card
							cardTitle="Sehn LH et al. Blood Adv 2022"
							cardDescription="Polatuzumab vedotin plus bendamustine and rituximab in relapsed/refractory DLBCL: survival update and new extension cohort data"
							cardLink="https://ashpublications.org/bloodadvances/article/6/2/533/477889/Polatuzumab-vedotin-plus-bendamustine-and"
							openModalHandler={openModalHandler}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Cards;